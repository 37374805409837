import React from "react"

import Typography from "./Typography"

import { Color } from "constants/V2/color"

type ListItemProps = {
  bodyText: string
  textColor: Color
  titleText: string
  listNumber: number
}

const ListItem = ({
  bodyText,
  titleText,
  textColor,
  listNumber,
}: ListItemProps) => {
  return (
    <div className="grid grid-cols-[auto_1fr] grid-rows-[auto_auto] gap-x-[5px] gap-y-4 sm:gap-x-24">
      <div className="sm:row-span-2 sm:flex sm:h-[50px] sm:w-[50px] sm:items-center sm:justify-center sm:self-start sm:rounded-full sm:bg-light-blue sm:px-[13px] sm:py-[3px]">
        <Typography
          leadingNone
          font="grotesk"
          weight="medium"
          color={textColor}
          size="lead-sm-dual"
          text={`${listNumber}.`}
          className="pl-4 sm:!text-blue"
        />
      </div>
      <Typography
        leadingNone
        font="grotesk"
        weight="medium"
        text={titleText}
        color={textColor}
        size="lead-sm-dual"
      />
      <Typography
        weight="book"
        size="body-md"
        font="grotesk"
        text={bodyText}
        color={textColor}
        className="col-span-2 sm:col-span-1"
      />
    </div>
  )
}

export default ListItem
