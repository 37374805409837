import { storyblokEditable } from "gatsby-source-storyblok"
import React from "react"

import MasterBulletPoints from "../../../../../V2/Cards/Content/MasterBulletPoints"

import { getUrlFromStoryblokLink, linkIsNotNull } from "@utils/storyblok"

const StoryblokBulletPointBulletItemsCard = ({
  blok,
}: Storyblok.BlokProps<Storyblok.BulletPointBulletItemsCard>) => {
  return (
    <MasterBulletPoints
      itemType="bullet_items"
      backgroundColor={blok.backgroundColor}
      superscriptText={blok.superscriptText}
      descriptionText={blok.bodyText}
      title={blok.headerText}
      headerSize={blok.headerSize}
      textAlignment={blok.textAlignment}
      body={blok.bodyText}
      bulletItems={blok.bulletItems}
      linkText={blok.ctaText}
      linkUrl={
        blok.ctaLink && linkIsNotNull(blok.ctaLink)
          ? getUrlFromStoryblokLink(blok.ctaLink)
          : undefined
      }
      // @ts-ignore to have more strict typing compared to Storybloks generic object typing
      {...storyblokEditable(blok)}
    />
  )
}

export default StoryblokBulletPointBulletItemsCard
